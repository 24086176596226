import { Helmet } from "react-helmet";
import { websiteName } from "../../config/webConfig";
import { Link, useParams } from "react-router-dom";
import { usePricing } from "../../firebase/fbQueries";
import { db } from "../../firebase/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faClose,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";

export const PricingPage = () => {
  const { path } = useParams();
  const pricing = usePricing(db);
  const priceSelected = pricing.find((price) => price.path === path);

  return (
    <>
      {priceSelected ? (
        <>
          <Helmet>
            <title>
              {priceSelected.title} - {websiteName}
            </title>
          </Helmet>

          <section className="section">
            <div className="container">
              <div className="page">
                <div className="column">
                  <div className="block">
                    <figure>
                      <img
                        src={priceSelected.image}
                        alt={`Vista previa de: ${priceSelected.title}.`}
                      />
                    </figure>
                  </div>
                </div>
                <div className="column">
                  <div className="block">
                    <h1>{priceSelected.title}</h1>
                    <h2>{`$${priceSelected.price.toFixed(2)} USD`}</h2>
                  </div>
                  <div className="block">
                    <div className="row g-8">
                      <Link className="button ghost">Cita</Link>
                      <Link className="button primary">Contratar</Link>
                    </div>
                  </div>
                  {priceSelected.desc && (
                    <div className="block">
                      <b>Descripción:</b>
                      <p>{priceSelected.desc}</p>
                    </div>
                  )}
                  {(priceSelected.features || priceSelected.does) && (
                    <div className="block">
                      {priceSelected.features && (
                        <ul>
                          <li>
                            <b>Incluye: </b>
                          </li>
                          {priceSelected.features.map((item, index) => (
                            <li key={index}>
                              <span className="icon">
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  color="green"
                                  size="sm"
                                />
                              </span>
                              <span className="text">{item}</span>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  )}
                  {priceSelected.does && (
                    <div className="block">
                      {priceSelected.does && (
                        <ul>
                          <li>
                            <b>No incluye: </b>
                          </li>
                          {priceSelected.does.map((item, index) => (
                            <li key={index}>
                              <span className="icon">
                                <FontAwesomeIcon
                                  icon={faClose}
                                  color="firebrick"
                                  size="sm"
                                />
                              </span>
                              <span className="text">{item}</span>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  )}
                  {priceSelected.faqs && (
                    <div className="block">
                      <b>Preguntas frecuentes</b>
                      <ul>
                        <li>
                          <span className="icon">
                            <FontAwesomeIcon icon={faQuestion} size="sm" />
                          </span>
                          <span className="text">
                            ¿Qué es una página de aterrizaje?
                          </span>
                        </li>
                        <li>
                          <span className="icon">
                            <FontAwesomeIcon icon={faQuestion} size="sm" />
                          </span>
                          <span className="text">
                            ¿Qué es una página de aterrizaje?
                          </span>
                        </li>
                        <li>
                          <span className="icon">
                            <FontAwesomeIcon icon={faQuestion} size="sm" />
                          </span>
                          <span className="text">
                            ¿Qué es una página de aterrizaje?
                          </span>
                        </li>
                        <li>
                          <span className="icon">
                            <FontAwesomeIcon icon={faQuestion} size="sm" />
                          </span>
                          <span className="text">
                            ¿Qué es una página de aterrizaje?
                          </span>
                        </li>
                        <li>
                          <span className="icon">
                            <FontAwesomeIcon icon={faQuestion} size="sm" />
                          </span>
                          <span className="text">
                            ¿Qué es una página de aterrizaje?
                          </span>
                        </li>
                        <li>
                          <span className="icon">
                            <FontAwesomeIcon icon={faQuestion} size="sm" />
                          </span>
                          <span className="text">
                            ¿Qué es una página de aterrizaje?
                          </span>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </>
      ) : null}
    </>
  );
};
